<template>
  <button
    class="px-4 font-bold"
    :class="{ 'opacity-50': disabled }"
    :disabled="disabled"
  >
    <slot />
  </button>
</template>

<script lang="ts">
import { Prop } from 'vue-property-decorator'
import { Options, Vue } from 'vue-class-component'

@Options({})
export default class Button extends Vue {
  @Prop() disabled!: boolean
}
</script>
