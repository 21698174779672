<template>
  <div
    v-if="!getRefreshToken && userLoaded"
    class="p-4 bg-red-50 border border-red-600 mb-4 rounded text-sm"
  >
    You do not have authentitcated against ZohoInvoice yet. <a
      :href="zohoAuthUrl"
      class="font-bold underline"
    >Please click here</a> to authenticate yourself.
  </div>
</template>

<script lang="ts">
import config from 'config'
import { Options, Vue } from 'vue-class-component'
import { mapGetters } from 'vuex'
import { stringifyUrl } from 'query-string'

@Options({
  computed: {
    ...mapGetters({
      userLoaded: 'user/loaded',
      getRefreshToken: 'zohoInvoice/getRefreshToken'
    })
  }
})
export default class AuthButton extends Vue {
  userLoaded!: boolean
  getRefreshToken!: string | boolean

  get zohoAuthUrl (): string {
    const url = 'https://accounts.zoho.com/oauth/v2/auth'

    const prompt = this.getRefreshToken ? {} : { prompt: 'consent' }

    return stringifyUrl({
      url,
      query: {
        client_id: config.zohoInvoice.clientId,
        redirect_uri: config.api.baseUrl + '/api/zoho-invoice/auth',
        scope: this.zohoPermissions,
        response_type: 'code',
        access_type: 'offline',
        state: 'testing',
        ...prompt
      }
    })
  }

  get zohoPermissions (): string {
    return [
      'ZohoInvoice.invoices.READ',
      'ZohoInvoice.projects.READ',
      'ZohoInvoice.projects.CREATE',
      'ZohoInvoice.settings.READ'
    ].join(',')
  }
}
</script>
