
import { Options, Vue } from 'vue-class-component'
import { mapGetters } from 'vuex'
import { UserProfile } from '@/store/user'

import ButtonComponent from '@/components/Form/Button.vue'
import LabelComponent from '@/components/Form/Label.vue'
import ZohoInvoiceAuthButton from '@/components/ZohoInvoice/AuthButton.vue'

@Options({
  components: {
    ButtonComponent,
    LabelComponent,
    ZohoInvoiceAuthButton
  },
  computed: {
    ...mapGetters({
      uid: 'user/getUid',
      profile: 'user/getProfile',
      isLoggedIn: 'user/isLoggedIn'
    })
  }
})
export default class Settings extends Vue {
  uid!: string | boolean
  profile!: UserProfile
  isLoggedIn!: boolean

  loading = false

  form = [
    {
      key: 'togglAccessToken',
      label: 'Toggl.com Access-Token'
    },
    {
      key: 'togglWorkspaceId',
      label: 'Toggl.com Project-ID',
      break: true
    },
    {
      key: 'zohoInvoiceOrganisationId',
      label: 'ZohoInvoice Organisation-ID',
      break: true
    },
    {
      key: 'gapiInvoiceTemplateId',
      label: 'Google-Docs Invoice-Template ID'
    }
  ]

  async save (): Promise<void> {
    this.loading = true
    await this.$store.dispatch('user/updateUserProfile', this.profile)
    this.loading = false
  }
}
