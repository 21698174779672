<template>
  <label
    :for="forName"
    class="w-full text-sm px-2 pb-2"
  >
    <slot />
  </label>
</template>

<script lang="ts">
import { Prop } from 'vue-property-decorator'
import { Options, Vue } from 'vue-class-component'

@Options({})
export default class TogglProjectSelect extends Vue {
  @Prop() forName!: string
}
</script>
