<template>
  <div
    v-if="isLoggedIn"
    class="setting"
  >
    <zoho-invoice-auth-button />
    <form class="flex flex-wrap -mx-2">
      <template
        v-for="input in form"
        :key="input.key"
      >
        <div class="w-full md:w-1/2 lg:w-1/4 mx-2 flex flex-wrap items-center">
          <label-component :for-name="input.key">
            {{ input.label }}
          </label-component>
          <input
            :id="input.key"
            v-model="profile[input.key]"
            class="w-full border px-4 py-2 mb-4 outline-none rounded focus:border-black"
            :disabled="loading"
          >
        </div>
        <div
          v-if="input.break"
          class="w-full"
        />
      </template>
      <div class="w-full">
        <button-component
          :disabled="loading"
          class="py-2"
          @click.prevent="save"
        >
          {{ loading ? 'Loading ...' : 'Save settings' }}
        </button-component>
      </div>
    </form>
  </div>
</template>

<script lang="ts">
import { Options, Vue } from 'vue-class-component'
import { mapGetters } from 'vuex'
import { UserProfile } from '@/store/user'

import ButtonComponent from '@/components/Form/Button.vue'
import LabelComponent from '@/components/Form/Label.vue'
import ZohoInvoiceAuthButton from '@/components/ZohoInvoice/AuthButton.vue'

@Options({
  components: {
    ButtonComponent,
    LabelComponent,
    ZohoInvoiceAuthButton
  },
  computed: {
    ...mapGetters({
      uid: 'user/getUid',
      profile: 'user/getProfile',
      isLoggedIn: 'user/isLoggedIn'
    })
  }
})
export default class Settings extends Vue {
  uid!: string | boolean
  profile!: UserProfile
  isLoggedIn!: boolean

  loading = false

  form = [
    {
      key: 'togglAccessToken',
      label: 'Toggl.com Access-Token'
    },
    {
      key: 'togglWorkspaceId',
      label: 'Toggl.com Project-ID',
      break: true
    },
    {
      key: 'zohoInvoiceOrganisationId',
      label: 'ZohoInvoice Organisation-ID',
      break: true
    },
    {
      key: 'gapiInvoiceTemplateId',
      label: 'Google-Docs Invoice-Template ID'
    }
  ]

  async save (): Promise<void> {
    this.loading = true
    await this.$store.dispatch('user/updateUserProfile', this.profile)
    this.loading = false
  }
}
</script>
